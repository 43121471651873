import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

export const query = graphql`
  query {
    headshot: file(
      relativePath: { eq: "John-de-Rooy-headshot-6356.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 384, maxHeight: 384) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aipp: file(
      relativePath: { eq: "AIPP-Licentiate-logo.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 270, maxHeight: 270) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const About = ({data}) => (
  <Layout>
    <SEO title="About" />
    <section className="hero is-small is-white">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered is-reversed-mobile">
            <div className="column is-8">
              <div className="section">
                <h1 className="title is-spaced is-size-3">
                  About
                  <span className="is-hidden-touch">
                    <br></br>
                  </span>
                </h1>
                <h3 className="subtitle is-5">
                  Tableland Photography provides professional photography
                  services to all of North Queensland from their base on the
                  Atherton Tableland. They are passionate about giving you a
                  professional image which you will be truly proud to put on
                  display.
                  <br /> <br />
                  John de Rooy is an Accredited Professional Photographer, with
                  the Australian Institute of Professional Photography (AIPP)
                  and has been awarded the distinction of Honorary Life Member
                  of that body for his service to the industry. John’s wife Pam
                  received the distinguished award of Claude McCarthy Award for
                  services to the profession in North Queensland. John served on
                  the National Board of the Institute for 8 years with 2 years
                  as National Chairman.
                  <br />
                  <br />
                  John began his photographic career as a forensic crime scene
                  photographer working all over North Queensland for 17 years,
                  and then John and Pam ran their Photographic Studio in
                  Townsville for 20 years, creating images for business,
                  government, commercial, family portraiture, schools and
                  weddings.
                  <br />
                  <br />
                  Photography is his passion; starting at a time when film and
                  the darkroom were the norm his knowledge of the traditional
                  photographic process is extensive as is his experience with
                  digital technologies and software.
                  <br />
                  <br />
                  From 2015 to 2018 John and Pam were privileged to manage
                  Australia’s largest ever coordinated portrait project;
                  “Reflections – Honouring our WWII Veterans” where they managed
                  the photography of 6,500 WWII Veterans all over Australia,
                  undoubtedly their proudest achievement in photography.
                  <br />
                  <br />
                  John and his wife Pam have completed photographic projects all
                  over North Queensland for over 30 years and have now based
                  their business on the Atherton Tableland near Malanda.
                </h3>
              </div>
            </div>

            <div className="column is-4">
              <div className="section has-text-centered">
              <figure className="image image-border">
                <Img fluid={data.headshot.childImageSharp.fluid} alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="columns is-vcentered is-reversed-mobile">
          <div className="column is-9">
            <div className="section">
              <h1 className="title is-spaced is-size-3" id="aipp">
                Why use an Accredited Photographer?
                <span className="is-hidden-touch">
                  <br></br>
                </span>
              </h1>
              <h3 className="subtitle is-5">
                Being a professional in every facet of business does not come by
                chance; it takes dedication, study, passion, commitment and
                financial investment.
                <br />
                <br />
                The Australian Institute of Professional Photography (AIPP)
                complying with strict government guidelines and requirements
                accredits photographers. Accredited Photographers have to adhere
                to a minimum standard, demonstrate their skills by portfolios
                submission with regular reviews, and similar to other
                professions they also have to participate in ongoing
                professional development/training to ensure they stay abreast of
                technology.
                <br />
                <br />
                Legal requirements are imposed to ensure public liability
                insurance is in place and the photographer adheres to all
                business and legal requirements and follows a recognised digital
                asset management regime.
                <br />
                <br />
                Accredited Photographers are required to follow a strict code of
                practice that prohibits unethical marketing or sales techniques,
                with a requirement to advise a client if they are not qualified
                to undertake a project.
                <br />
                <br />
                Accreditation ensures the photographer has access to skill sets
                necessary for an ongoing viable business but more importantly it
                is there to protect the customer and ensure they get the
                products service and protection they are entitled to.
              </h3>
            </div>
          </div>
          <div className="column is-3">
            <div className="section">
              <div className="has-text-centered">
              <figure className="image image-border">
                <Img fluid={data.aipp.childImageSharp.fluid} alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default About
